<template>
  <div class="usl-right__content">
    <div class="usl-right__content--top">
      <div>
        <LxpButton
          ref="saveButton"
          class="usl-right__content--top_btn"
          :class="{
            'content--top__save-btn': isSaved,
            'content--top__failed-btn': isSavedFailed
          }"
          @click="onClick"
        >
          {{ saveButtonText }}</LxpButton
        >
      </div>
      <label>What is the course overview?</label>
      <p>
        The Course overview is meant to provide learner with a clear and concise
        description of a course, including its goals, objectives, content, and
        requirements.
      </p>
    </div>
    <div class="usl-right__content--bottom">
      <p>
        The overview should give leaners an idea of what they can expect to
        learn, how the course will be taught, what assignments and assessments
        will be required, and what resources will be available to them.
      </p>
    </div>
    <div class="usl-right__content--bottom">
      <p>
        A good course overview should help learners determine whether the course
        is a good fit for their academic and career goals.
      </p>
    </div>
    <div class="usl-right__content--bottom">
      <p>
        Additionally, a well-written course overview can help instructors
        communicate their expectations and goals for the course, and can help to
        foster a positive and productive learning environment for everyone
        involved.
      </p>
    </div>
  </div>
</template>

<script>
import { LxpButton } from "didactica";
export default {
  components: { LxpButton },
  data() {
    return {
      saveButtonText: "Save Changes",
      isSavedFailed: false,
      isSaved: false
    };
  },
  mounted() {
    this.$root.$on("CourseOverviewSaved", data => {
      this.saveButtonText = data.text;
      this.isSaved = data.isSaved;
      this.isSavedFailed = data.isSavedFailed;
      if (this.isSaved) {
        setTimeout(() => {
          this.isSaved = false;
        }, 5000);
      }
    });
  },
  methods: {
    onClick() {
      this.$root.$emit("CourseOverviewSave");
      const buttonElement = this.$refs.saveButton.$el;
      const buttonHeight = buttonElement.offsetHeight;
      const textHeight = buttonElement.scrollHeight;
      if (textHeight > buttonHeight) {
        buttonElement.style.height = `${textHeight}px`;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.usl-right__content {
  .usl-right__content--top {
    margin-bottom: 10px;
  }
  .usl-right__content--top_btn {
    height: auto !important;
    width: 100%;
    margin-bottom: 20px !important;
    min-height: 40px !important;
  }
  .content--top__save-btn {
    background: $brand-success-400 !important;
  }
  .content--top__failed-btn {
    background: $brand-warning-400 !important;
  }
  label {
    @include body-regular(500);
    margin-bottom: 10px;
  }
  p {
    @include body-medium;
  }
}
</style>
