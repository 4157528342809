var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"usl-right__content"},[_c('div',{staticClass:"usl-right__content--top"},[_c('div',[_c('LxpButton',{ref:"saveButton",staticClass:"usl-right__content--top_btn",class:{
          'content--top__save-btn': _vm.isSaved,
          'content--top__failed-btn': _vm.isSavedFailed
        },on:{"click":_vm.onClick}},[_vm._v(" "+_vm._s(_vm.saveButtonText))])],1),_c('label',[_vm._v("What is the course overview?")]),_c('p',[_vm._v(" The Course overview is meant to provide learner with a clear and concise description of a course, including its goals, objectives, content, and requirements. ")])]),_vm._m(0),_vm._m(1),_vm._m(2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"usl-right__content--bottom"},[_c('p',[_vm._v(" The overview should give leaners an idea of what they can expect to learn, how the course will be taught, what assignments and assessments will be required, and what resources will be available to them. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"usl-right__content--bottom"},[_c('p',[_vm._v(" A good course overview should help learners determine whether the course is a good fit for their academic and career goals. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"usl-right__content--bottom"},[_c('p',[_vm._v(" Additionally, a well-written course overview can help instructors communicate their expectations and goals for the course, and can help to foster a positive and productive learning environment for everyone involved. ")])])
}]

export { render, staticRenderFns }